import React, { useState } from "react";
import Card from "../../components/card/Card";
import "./Contact.css";
import { FaPhoneAlt, FaEnvelope, FaTelegramPlane } from "react-icons/fa";

import { GoLocation } from "react-icons/go";
import { toast } from "react-toastify";
import axios from "axios";
import { BACKEND_URL } from "../../services/authService";

const Contact = () => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const data = {
    subject,
    message,
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BACKEND_URL}/api/contactus`, data);
      setSubject("");
      setMessage("");
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="contact">
      <h3 className="contactTop">Contact Us</h3>
      <div className="section">
        <div className="formDiv">
          <form onSubmit={sendEmail}>
            <Card cardClass="card1">
              <label>Subject</label>
              <input
                type="text"
                name="subject"
                placeholder="Subject"
                required
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <label>Message</label>
              <textarea
                cols="30"
                rows="10"
                name="message"
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <button className="--btn --btn-primary">
                Send Message{" "}
                <FaTelegramPlane
                  style={{ color: "white", paddingLeft: "0.3rem" }}
                />
              </button>
            </Card>
          </form>
        </div>
        <div className="detailsDiv">
          <div className="details">
            <Card cardClass={"card2"}>
              <h3>Our Contact Information</h3>
              <p>
                Fill the form or contact us via other channels listed below:
              </p>

              <div className="icons">
                <span>
                  <FaPhoneAlt />
                  <p>+91-6354814759</p>
                </span>
                <span>
                  <FaEnvelope />
                  <p>hello@linear-amptech.com</p>
                </span>
                <span>
                  <GoLocation />
                  <p>Roorkee, Uttarakhand</p>
                </span>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
