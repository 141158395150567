import React, { useEffect, useState } from "react";
import { SpinnerImg } from "../../loader/Loader";
import { useParams } from "react-router-dom";
import "./ProductList.css";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { BiFilterAlt } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import Search from "../../search/Search";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import {
  FILTER_BY_CATEGORY,
  FILTER_PRODUCTS,
  selectFilteredPoducts,
} from "../../../redux/features/product/filterSlice";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  deleteProduct,
  getProducts,
  CALC_CATEGORY,
} from "../../../redux/features/product/productSlice";

import {
  CALC_OUTOFSTOCK,
  selectOutOfStock,
} from "../../../redux/features/product/productSlice";
import { Link } from "react-router-dom";
import { selectCategory } from "../../../redux/features/inventory/inventorySlice";

axios.defaults.withCredentials = true;

const ProductList = ({ products, isLoading }) => {
  const { dashboardId } = useParams();

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("Category");
  const filteredProducts = useSelector(selectFilteredPoducts);

  const outOfStock = useSelector(selectOutOfStock);
  const categories = useSelector(selectCategory);
  const cat = useSelector(selectCategory);
  const dispatch = useDispatch();

  const shortenText = (text, n) => {
    if (text.length > n) {
      const shortenedText = text.substring(0, n).concat("...");
      return shortenedText;
    }
    return text;
  };

  const delProduct = async (id) => {
    await dispatch(deleteProduct(id));
    await dispatch(getProducts(dashboardId));
  };

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Delete Product",
      message: "Are you sure you want to delete this product.",
      buttons: [
        {
          label: "Delete",
          onClick: () => delProduct(id),
        },
        {
          label: "Cancel",
          // onClick: () => alert('Click No')
        },
      ],
    });
  };

  //   Begin Pagination
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 6;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(filteredProducts.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredProducts.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, filteredProducts]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredProducts.length;
    setItemOffset(newOffset);
  };
  //   End Pagination

  useEffect(() => {
    dispatch(FILTER_PRODUCTS({ products, search }));
    dispatch(CALC_OUTOFSTOCK(products));
    dispatch(CALC_CATEGORY(products));
  }, [products, search, dispatch]);

  //filter products on category base
  const handleCategory = async (category) => {
    await dispatch(getProducts(dashboardId));
    dispatch(FILTER_BY_CATEGORY({ products, category }));
  };

  return (
    <div className="product-list">
      <div className="table ">
        <div className="--flex-between --flex-dir-column ">
          <span>
            <h3>Inventory Items</h3>
          </span>
          <div className="search-filter">
            <span className="category">
              <select
                id="category"
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                  handleCategory(e.target.value);
                }}
              >
                <option disabled selected>
                  Category
                </option>
                <option>All</option>
                {!isLoading &&
                  categories &&
                  categories.map((category) => {
                    return <option>{category}</option>;
                  })}
                {outOfStock > 0 && <option>OUT OF STOCK</option>}
              </select>
            </span>
            <span>
              <Search
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </span>
          </div>
        </div>

        {isLoading && <SpinnerImg />}

        <div className="table">
          {!isLoading && products.length === 0 ? (
            <p>-- No product found, please add a product...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>s/n</th>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {currentItems.map((product, index) => {
                  const { _id, name, category, price, quantity } = product;
                  return (
                    <tr key={_id}>
                      <td>{index + 1}</td>
                      <td>{shortenText(name, 16)}</td>
                      <td>{category}</td>
                      <td>
                        {"₹"}
                        {price}
                      </td>
                      <td>{quantity}</td>

                      <td className="icons">
                        <span>
                          <Link to={`/product-detail/${_id}`}>
                            <AiOutlineEye
                              size={25}
                              color={"#4b4b4b"}
                              title="View Details"
                            />
                          </Link>
                        </span>
                        <span>
                          <Link to={`/edit-product/${_id}`}>
                            <FaEdit
                              size={20}
                              color={"#4b4b4b"}
                              title="Edit Product"
                            />
                          </Link>
                        </span>
                        <span>
                          <FaTrashAlt
                            size={20}
                            color={"#4b4b4b"}
                            onClick={() => confirmDelete(_id)}
                            title="Delete"
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>

        <ReactPaginate
          breakLabel="..."
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="Prev"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeLinkClassName="activePage"
        />
      </div>
    </div>
  );
};

export default ProductList;
