import { ShowOnLogin, ShowOnLogout } from "../../components/protect/HiddenLink";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectName, SET_LOGIN } from "../../redux/features/auth/authSlice";
import { logoutUser } from "../../services/authService";

import { Link } from "react-router-dom";
import { IoMdLogOut } from "react-icons/io";
import "./Navbar.css";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logout = async () => {
    await logoutUser();
    await dispatch(SET_LOGIN(false));
    navigate("/login");
  };
  return (
    <div className="navbar-class">
      <nav className="container --flex-between  " style={{ boxShadow: "none" }}>
        <div className="navbar-logo">
          <img src="/amptech.png" style={{ width: "100px" }} alt="icon"></img>
        </div>

        <ul className="home-links">
          <ShowOnLogout>
            <li>
              <button className="--btn  ">
                <Link to="/register" style={{ color: "#011021" }}>
                  Register
                </Link>
              </button>
            </li>
          </ShowOnLogout>
          <ShowOnLogout>
            <li>
              <button className="--btn --btn-primary ">
                <Link to="/login">Login</Link>
              </button>
            </li>
          </ShowOnLogout>

          <ShowOnLogin>
            <li>
              <button className="--btn --btn-primary">
                <Link to="/dashboard/my">Dashboard</Link>
              </button>
            </li>

            <li>
              <button
                className="--btn --btn-primary header-btn "
                onClick={logout}
              >
                <IoMdLogOut /> Logout
              </button>
            </li>
          </ShowOnLogin>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
