import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filteredProducts: [],
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    FILTER_BY_CATEGORY(state, action) {
      const { products, category } = action.payload;
      if (category === "All") {
        const tempFilterProduct = products.filter((product) => {
          return product;
        });
        state.filteredProducts = tempFilterProduct;
        return;
      }

      if (category === "OUT OF STOCK") {
        const tempFilterProduct = products.filter((product) => {
          if (product.quantity == 0) return product;
        });
        state.filteredProducts = tempFilterProduct;
        return;
      }
      const tempFilterProduct = products.filter((product) => {
        if (product.category === category) return product;
      });
      state.filteredProducts = tempFilterProduct;
    },
    FILTER_PRODUCTS(state, action) {
      const { products, search } = action.payload;
      const tempProducts = products.filter(
        (product) =>
          product.name.toLowerCase().includes(search.toLowerCase()) ||
          product.category.toLowerCase().includes(search.toLowerCase())
      );

      state.filteredProducts = tempProducts;
    },
  },
});

export const { FILTER_PRODUCTS, FILTER_BY_CATEGORY } = filterSlice.actions;

export const selectFilteredPoducts = (state) => state.filter.filteredProducts;

export default filterSlice.reducer;
