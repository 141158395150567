import React from "react";
// import { RiProductHuntLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import "./Home.css";
import heroImg from "../../assets/inv-img.png";

import { ShowOnLogin, ShowOnLogout } from "../../components/protect/HiddenLink";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";

const Home = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="home home">
      <Navbar />
      {/* HERO SECTION */}
      <section className="container hero">
        <div className="hero-text">
          <h2>{user ? "Welcome " + user.name : "Inventory System"} </h2>
        </div>

        <div className="hero-image">
          <img src={heroImg} width="600px" height="580px" alt="Inventory" />
        </div>
      </section>
      <Footer />
    </div>
  );
};

const NumberText = ({ num, text }) => {
  return (
    <div className="--mr">
      <h3 className="--color-white">{num}</h3>
      <p className="--color-white">{text}</p>
    </div>
  );
};

export default Home;
