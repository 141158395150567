import { FaTh, FaRegChartBar } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { ImProfile } from "react-icons/im";
import { MdAddBox } from "react-icons/md";
import { FaBug } from "react-icons/fa";
import { FiShare2 } from "react-icons/fi";
const menu = [
  {
    title: "Dashboard",
    icon: <FaTh />,
    path: "/dashboard/my",
  },
  {
    title: "Share Inventory",
    icon: <FiShare2 />,
    path: "/share-inventory",
  },
  {
    title: "Add Product",
    icon: <MdAddBox />,
    path: "/add-product",
  },
  {
    title: "Account",
    icon: <FaRegChartBar />,
    childrens: [
      {
        title: "Profile",
        path: "/profile/my",
        icon: <CgProfile />,
      },
      {
        title: "Edit Profile",
        path: "/edit-profile",
        icon: <ImProfile />,
      },
    ],
  },
  {
    title: "Report Bug",
    icon: <FaBug />,
    path: "/contact-us",
  },
];

export default menu;
