import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import inventoryService from "./inventoryService";
import { toast } from "react-toastify";

const initialState = {
  user: null,
  users: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  totalStoreValue: 0,
  outOfStock: 0,
  category: [],
  assignedProducts: [],
};

// Search Users
export const searchUser = createAsyncThunk(
  "inventory/searchuser",
  async ({ term }, thunkAPI) => {
    try {
      return await inventoryService.searchUser(term);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Assign Product
export const assignProduct = createAsyncThunk(
  "inventory/assignproduct",
  async (formData, thunkAPI) => {
    try {
      console.log(formData);

      return await inventoryService.assignProduct(formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Return products
export const returnProduct = createAsyncThunk(
  "inventory/returnproduct",
  async (formData, thunkAPI) => {
    try {
      return await inventoryService.returnProduct(formData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get inventory users
export const getInventoryUsers = createAsyncThunk(
  "inventory/geinventoryusers",
  async (thunkAPI) => {
    try {
      return await inventoryService.getInventoryUsers();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Share Inventory
export const shareInventory = createAsyncThunk(
  "inventory/shareinventory",
  async ({ email }, thunkAPI) => {
    console.log(email);
    try {
      return await inventoryService.shareInventory(email);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Share Inventory
export const removeUser = createAsyncThunk(
  "inventory/removeuser",
  async (id, thunkAPI) => {
    console.log(id);
    console.log(id);
    try {
      return await inventoryService.removeUser(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// fetch user assigned products
export const fetchProducts = createAsyncThunk(
  "inventory/fetchproducts",
  async (id, thunkAPI) => {
    console.log(id);
    try {
      return await inventoryService.fetchProducts(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(assignProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(assignProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        // (action.payload);
        // state.products.push(action.payload);
        toast.success("Product Assign SUCCESSFULLY!!!");
      })
      .addCase(assignProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(returnProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(returnProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        // (action.payload);
        // state.products.push(action.payload);
        toast.success("Product RETURNNN SUCCESSFULLY");
      })
      .addCase(returnProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(searchUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        // (action.payload);
        // state.users = action.payload;
      })
      .addCase(searchUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(getInventoryUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getInventoryUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.users = action.payload;
      })
      .addCase(getInventoryUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(shareInventory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(shareInventory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.success("Shared Inventory Successful!!");
      })
      .addCase(shareInventory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(removeUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.success("User Remvoe Successfully!!");
      })
      .addCase(removeUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});

export const { CALC_STORE_VALUE, CALC_OUTOFSTOCK, CALC_CATEGORY } =
  inventorySlice.actions;

export const selectIsLoading = (state) => state.inventory.isLoading;
export const selectInventoryUsers = (state) => state.inventory.users;
export const selectTotalStoreValue = (state) => state.product.totalStoreValue;
export const selectOutOfStock = (state) => state.product.outOfStock;
export const selectCategory = (state) => state.product.category;

export default inventorySlice.reducer;
