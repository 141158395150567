import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectName, SET_LOGIN } from "../../redux/features/auth/authSlice";
import { logoutUser } from "../../services/authService";
import "./Header.css";

import { IoMdLogOut } from "react-icons/io";
import { MdPeopleAlt } from "react-icons/md";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const name = useSelector(selectName);

  const logout = async () => {
    await logoutUser();
    await dispatch(SET_LOGIN(false));
    navigate("/login");
  };

  return (
    <div className="header">
      <div className="">
        <h3>Welcome, {name}</h3>
      </div>
      <div className="header-btn-div">
        <div className="">
          <div className="">
            <a
              className="--btn --btn-primary  header-btn"
              href="https://hrms.linear-amptech.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MdPeopleAlt style={{ marginRight: "0.4rem" }} />
              HRM App
            </a>
          </div>
        </div>
        <div>
          <button className="--btn --btn-primary header-btn " onClick={logout}>
            <IoMdLogOut /> Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
