import React, { useState } from "react";
import "./Sidebar.css";
import { HiMenuAlt3 } from "react-icons/hi";
// import { RiProductHuntLine } from "react-icons/ri";
import menu from "../../data/sidebar";
import SidebarItem from "./SidebarItem";
import { useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  return (
    <div className="layout ">
      <div className="sidebar " style={{ width: isOpen ? "240px" : "80px" }}>
        <div className="top_section">
          <div className="logo " style={{ display: isOpen ? "block" : "none" }}>
            <img // img in expanded navbar.
              className="logo-icon"
              src="/amptech.png"
              alt="icon"
              style={{ cursor: "pointer", width: "100px" }}
              onClick={goHome}
            ></img>
          </div>
          <div // collapsing icon.
            className="bars "
            style={{ marginLeft: isOpen ? "40px" : "0px" }}
          >
            <HiMenuAlt3 onClick={toggle} />
          </div>
        </div>
        <div className=" h-fit ">
          {menu.map((item, index) => {
            return (
              <div className="side-item">
                <SidebarItem key={index} item={item} isOpen={isOpen} />
              </div>
            );
          })}
        </div>
      </div>

      <main
        style={{
          paddingLeft: isOpen ? "240px" : "80px",
          transition: "all .6s",
        }}
      >
        {children}
      </main>
    </div>
  );
};

export default Sidebar;
