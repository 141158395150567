import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getProduct } from "../../redux/features/product/productSlice";
import {
  assignProduct,
  searchUser,
} from "../../redux/features/inventory/inventorySlice";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";
import axios from "axios";
import "./AssignProduct.css";
import { ImArrowRight } from "react-icons/im";
axios.defaults.withCredentials = true;

let moment = require("moment");

const AssignProduct = (props) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [date, setDate] = useState("");
  const [status, setStatus] = useState("Assign");
  const [consume, setConsume] = useState("null");
  const [deadline, setDeadline] = useState("unlimited");
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [name, setName] = useState("");
  const [userId, setUserID] = useState("");
  const [term, setTerm] = useState("");

  const { id } = useParams();

  //search user

  const searchUsers = async () => {
    if (term.length <= 1) {
      return;
    }
    const response = await dispatch(searchUser({ term }));
    console.log(response.payload);

    setUsers(response.payload);
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      searchUsers();
    }, 2000);

    return () => clearTimeout(timerId);
  }, [term]);

  //handle confirm
  const handleConfirm = async (e) => {
    e.preventDefault();

    if (!date || !quantity || !userId || !consume)
      return toast.error("All fields are required!");

    //validate userID
    if (!userId || !name) {
      return toast.error("Please select a valid user!");
    }

    //validate assign quantity
    if (
      Number(quantity) <= 0 ||
      Number(quantity) > Number(props.product.quantity)
    ) {
      return toast.error("Please enter valid quantity!");
    }

    //validate deadline
    if (deadline !== "unlimited" && +deadline <= 0) {
      return toast.error("Please enter a valid deadline!");
    }

    moment(Date.now()).diff(moment(new Date(date)), "days");

    //validate date
    if (moment(Date.now()).diff(moment(new Date(date)), "days") < 0) {
      return toast.error("We can't assign product in future date");
    }

    setForm(!form);

    const formDatas = new FormData();

    formDatas.append("userId", String(userId));
    formDatas.append("status", String(status));
    formDatas.append("consume", String(consume));
    formDatas.append("date", new Date(date));
    formDatas.append("quantity", Number(quantity));
    formDatas.append("productId", String(props.product._id));
    formDatas.append("deadline", Number(deadline));
    console.log(formDatas);
    console.log(userId);
    console.log(status);
    console.log(consume);
    console.log(date);
    console.log(quantity);
    console.log(props.product._id);
    console.log(deadline);

    await dispatch(
      assignProduct({
        userId: user._id,
        status,
        consume,
        date,
        quantity,
        productId: props.product._id,
        deadline,
      })
    );
    await dispatch(getProduct(props.product._id));

    setDate("");
    setConsume("null");
    setUsers([]);
    setUserID("");
    setName("");
    setQuantity("");
    setStatus("Assign");
    setDeadline("unlimited");
    setUser("");
    dispatch(getProduct(id));
  };

  // open form dailog box
  const clickHandler = () => {
    setForm(!form);
  };

  //select user
  const handleSelectUser = (user) => {
    setUserID(user._id);
    setName(user.name);
    setUser(user);
    console.log(user);
    setUsers([]);
  };

  //handle Quantity
  const handleChangeQuantity = (e) => {
    setQuantity(e.target.value);
  };

  //handle date
  const handleChangeDate = (e) => {
    setDate(e.target.value);
  };

  //handle status change
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  //handle change consume
  const handleChangeConsume = (e) => {
    setConsume(e.target.value);
  };

  //handledeadline
  const handleChangeDeadline = (e) => {
    setDeadline(e.target.value);
  };

  return (
    <div className="">
      {form ? (
        <div className="assign-form">
          <form className="form-assign">
            <div>
              <label for="assign">Name:</label>
              <input
                type="text"
                placeholder="Search name "
                value={user.name}
                required
                onChange={async (e) => {
                  setTerm(e.target.value.trim());
                  setName(e.target.value);
                  setUser(e.target.value);
                  if (term.length <= 1) {
                    console.log("TERM : " + term);
                    setUsers([]);
                    return;
                  }
                }}
              ></input>
            </div>
            <div>
              <div className="search-result">
                {users &&
                  users.map((user) => (
                    <div className="user">
                      <p onClick={() => handleSelectUser(user)}>
                        {user.name}
                        <br />
                        {user.email}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
            <div>
              <label for="assign-type">Status:</label>

              <select
                name="assign-type"
                id="assign-type"
                onChange={handleStatusChange}
              >
                <option value="Assign">Assign</option>
                <option value="Consume">Consume</option>
              </select>
            </div>

            {status === "Consume" && (
              <div>
                <label for="quantity">Consumed in:</label>

                <input
                  type="text"
                  id="consumed-in"
                  placeholder="Consumed in"
                  required
                  onChange={handleChangeConsume}
                ></input>
              </div>
            )}

            <div>
              <label for="quantity">Quantity:</label>

              <input
                type="number"
                id="quantity"
                placeholder="Quantity "
                required
                onChange={handleChangeQuantity}
                min="1"
                max={props.product.quantity}
              ></input>
            </div>

            <div>
              <label for="date">Date:</label>

              <input
                type="date"
                id="date"
                required
                onChange={handleChangeDate}
              ></input>
              {status === "Assign" && (
                <div>
                  <label for="deadline">Deadline (in days):</label>

                  <input
                    type="number"
                    id="deadline"
                    value={deadline}
                    required
                    min="1"
                    onChange={handleChangeDeadline}
                  ></input>
                </div>
              )}
            </div>
            <div className="cancel-confirm">
              <button
                type="button"
                className="cancel --btn"
                onClick={clickHandler}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="confirm --btn --btn-primary"
                onClick={handleConfirm}
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      ) : null}

      {!form ? (
        <button
          type="button"
          className="assign-btn --btn-primary --btn "
          onClick={clickHandler}
        >
          Assign
          <ImArrowRight
            style={{
              color: "white",
              marginTop: "0.5rem ",
              marginLeft: "0.5rem",
            }}
          />
        </button>
      ) : null}
    </div>
  );
};

export default AssignProduct;
