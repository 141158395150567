import React, { useState } from "react";
import styles from "./auth.module.css";
// import { BiLogIn } from "react-icons/bi";
import Card from "../../components/card/Card";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { loginUser, validateEmail } from "../../services/authService";
import { SET_LOGIN, SET_NAME } from "../../redux/features/auth/authSlice";
import Loader from "../../components/loader/Loader";
import Icon from "../../assets/android-icon-48x48.png";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const initialState = {
  email: "",
  password: "",
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setformData] = useState(initialState);
  const { email, password } = formData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });
  };

  const login = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      return toast.error("All fields are required");
    }

    if (!validateEmail(email)) {
      return toast.error("Please enter a valid email");
    }

    const userData = {
      email,
      password,
    };
    setIsLoading(true);
    try {
      const data = await loginUser(userData);
      // (data);
      await dispatch(SET_LOGIN(true));
      await dispatch(SET_NAME(data.name));
      localStorage.setItem("user", JSON.stringify(data));
      navigate("/dashboard/my");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Navbar />

      <div className={`container ${styles.auth}`}>
        {isLoading && <Loader />}
        <Card>
          <div className={styles.form} style={{ borderRadius: "20" }}>
            <div className="--flex-center">
              <img
                src="/amptech.png"
                style={{ width: "75px", marginBottom: "1rem" }}
                alt="Icon"
              />
            </div>
            <h2>Login</h2>

            <form onSubmit={login}>
              <input
                type="email"
                placeholder="Email"
                required
                name="email"
                value={email}
                onChange={handleInputChange}
              />
              <input
                type="password"
                placeholder="Password"
                required
                name="password"
                value={password}
                onChange={handleInputChange}
              />
              <button type="submit" className="--btn --btn-primary --btn-block">
                Login
              </button>
            </form>
            <Link to="/forgot">Forgot Password</Link>

            <span className={styles.register} style={{}}>
              <Link to="/">Home</Link>
              <p> &nbsp; Don't have an account? &nbsp;</p>
              <Link to="/register">Register</Link>
            </span>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Login;
