import React from "react";

const Footer = () => {
  return (
    <div className="--flex-center --py2">
      <p style={{ color: "#000ec" }}>Linear-AmpTech &copy; 2023</p>
    </div>
  );
};

export default Footer;
