import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Card from "../../components/card/Card";
import { SpinnerImg } from "../../components/loader/Loader";
import useRedirectLoggedOutUser from "../../customHook/useRedirectLoggedOutUser";
import { SET_NAME, SET_USER } from "../../redux/features/auth/authSlice";
import { getUser } from "../../services/authService";
import { useParams } from "react-router-dom";
import "./Profile.css";
import "../../components/product/productDetail/ProductDetail.css";
import moment from "moment";

const Profile = () => {
  const { id } = useParams();

  useRedirectLoggedOutUser("/login");
  const dispatch = useDispatch();

  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    async function getUserData() {
      const data = await getUser(id);
      // const products = await dispatch(fetchProducts(id));
      // console.log(products);

      setProfile(data);

      setIsLoading(false);
      if (id === "my") {
        await dispatch(SET_USER(data));
        await dispatch(SET_NAME(data.name));
      }
    }
    getUserData();
  }, [dispatch, id]);

  return (
    <div className="profile-div">
      <div className="profile --my2">
        {isLoading && <SpinnerImg />}
        <>
          {!isLoading && profile === null ? (
            <p>Something went wrong, please reload the page...</p>
          ) : (
            <center>
              <Card cardClass={"card --flex"}>
                <span className="profile-photo">
                  <img className="" src={profile?.photo} alt="profilepic" />
                </span>
                <span className="profile-data ">
                  <p className="">
                    <b className="  ">Name : </b>
                    <b className=" "> {profile?.name} </b>
                  </p>
                  {id === "my" && (
                    <p className="">
                      <b className="  ">Inventory Name : </b>
                      <b className=" "> {profile?.inventoryName} </b>
                    </p>
                  )}

                  <p className="">
                    <b className=" ">Email : </b>{" "}
                    <b className=""> {profile?.email}</b>
                  </p>
                  <p className="">
                    <b className=" ">Phone : </b>{" "}
                    <b className=""> {profile?.phone}</b>
                  </p>
                  <p className="">
                    <b className=" ">Bio : </b>{" "}
                    <b className=" "> {profile?.bio}</b>
                  </p>
                  {/* <div className="">
                </div> */}
                </span>
              </Card>
              <br />
              <br />

              {id === "my" && (
                <NavLink to="/edit-profile">
                  <button className="--btn --btn-primary">Edit Profile</button>
                </NavLink>
              )}
            </center>
          )}
        </>
      </div>
      {!profile ? (
        " "
      ) : (
        <div
          className="assign-table profile-table"
          style={{ boxShadow: "none" }}
        >
          <table>
            <caption className="caption">
              Total {profile.history.length} products assigned.{" "}
            </caption>
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>Assign Date</th>
              </tr>
            </thead>

            <tbody>
              {profile.history.map((his, index) => {
                const assignDate = his.assignDate;
                const productName = his.productId.name;
                const productId = his.productId._id;
                const quantity = his.consumeQuantity;

                return (
                  <tr key={his._id} style={{ borderLeft: "1px solid gray" }}>
                    <td>
                      <NavLink
                        to={`/product-detail/${productId}`}
                        className="view"
                        style={{ color: "black" }}
                      >
                        {" "}
                        {productName}
                      </NavLink>
                    </td>
                    <td>{quantity}</td>

                    <td> {moment(new Date(assignDate)).format("MMM Do YY")}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Profile;
