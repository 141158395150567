import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Card from "../../components/card/Card";
import Loader from "../../components/loader/Loader";
import { selectUser } from "../../redux/features/auth/authSlice";
import "./Profile.css";
import { toast } from "react-toastify";
import { updateUser } from "../../services/authService";
import ChangePassword from "../../components/changePassword/ChangePassword";
// import { CgProfile } from "react-icons/cg";

const EditProfile = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(selectUser);
  console.log(user);
  const { email } = user;

  useEffect(() => {
    if (!email) {
      navigate("/profile/my");
    }
  }, [email, navigate]);

  const initialState = {
    name: user?.name,
    email: user?.email,
    phone: user?.phone,
    bio: user?.bio,
    inventoryName: user?.inventoryName,
    photo: user?.photo,
  };
  const [profile, setProfile] = useState(initialState);
  const [profileImage, setProfileImage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleImageChange = (e) => {
    setProfileImage(e.target.files[0]);
  };

  const saveProfile = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // Handle Image upload
      let imageURL;
      if (
        profileImage &&
        (profileImage.type === "image/jpeg" ||
          profileImage.type === "image/jpg" ||
          profileImage.type === "image/png")
      ) {
        const image = new FormData();
        image.append("file", profileImage);
        image.append("cloud_name", "dfxnk6tco");
        image.append("upload_preset", "awj99ihw");

        // First save image to cloudinary
        const response = await fetch(
          "https://api.cloudinary.com/v1_1/dfxnk6tco/image/upload",
          { method: "post", body: image }
        );
        const imgData = await response.json();
        imageURL = imgData.url.toString();
        console.log(imageURL);

        // Save Profile
        const formData = {
          name: profile.name,
          phone: profile.phone,
          bio: profile.bio,
          inventoryName: profile.inventoryName,
          photo: profileImage ? imageURL : profile.photo,
        };

        const data = await updateUser(formData);

        toast.success("User updated");
        navigate("/profile/my");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <div className="profile --my2">
      {isLoading && <Loader />}
      <center>
        <Card cardClass={"card --flex-dir-column"}>
          <span className="profile-photo">
            <img src={user?.photo} alt="profilepic" />
          </span>
          <form className="--form-control --m" onSubmit={saveProfile}>
            <span className="profile-data">
              <p>
                <label>Name:</label>
                <input
                  type="text"
                  name="name"
                  value={profile?.name}
                  onChange={handleInputChange}
                />
              </p>
              <p>
                <label>Email:</label>
                <input
                  className="disabledInput"
                  type="text"
                  name="email"
                  value={profile?.email}
                  disabled
                />
                <br />
                <code>Email cannot be changed.</code>
              </p>
              <p>
                <label>Inventory Name:</label>
                <input
                  type="text"
                  name="inventoryName"
                  value={profile?.inventoryName}
                  onChange={handleInputChange}
                />
              </p>
              <p>
                <label>Phone:</label>
                <input
                  type="text"
                  name="phone"
                  value={profile?.phone}
                  onChange={handleInputChange}
                />
              </p>
              <p>
                <label>Bio:</label>
                <br />
                <textarea
                  name="bio"
                  value={profile?.bio}
                  onChange={handleInputChange}
                  cols="30"
                  rows="10"
                ></textarea>
              </p>
              <p>
                <label>Photo:</label>
                <input type="file" name="image" onChange={handleImageChange} />
              </p>
              <div className="saveProfileButton">
                <button className="--btn --btn-primary">Save</button>
              </div>
            </span>
          </form>
        </Card>
        <br />
        <ChangePassword />
      </center>
    </div>
  );
};

export default EditProfile;
