import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const API_URL = `${BACKEND_URL}/api/products/`;

// Search User
const searchUser = async (term) => {
  const response = await axios.post(`${BACKEND_URL}/api/inventory/searchuser`, {
    term,
  });
  console.log(response);
  return response.data;
};

// Assign Product
const assignProduct = async (formData) => {
  console.log(formData);
  const response = await axios.post(
    `${BACKEND_URL}/api/inventory/assignproduct`,
    formData
  );
  console.log(response);
  return response.data;
};

// Return Product
const returnProduct = async (formData) => {
  const response = await axios.post(
    `${BACKEND_URL}/api/inventory/returnproduct`,
    formData
  );
  console.log(response);
  return response.data;
};

// Fetch Inventory Users
const getInventoryUsers = async () => {
  const response = await axios.get(
    `${BACKEND_URL}/api/inventory/inventoryusers`
  );
  return response.data;
};

// Share Inventory
const shareInventory = async (email) => {
  console.log("INSIDE " + email);
  const response = await axios.patch(
    `${BACKEND_URL}/api/inventory/shareinventory`,
    { email }
  );
  return response.data;
};

// Remvoe User from Inventory files
const removeUser = async (id) => {
  console.log(id);
  const response = await axios.patch(
    `${BACKEND_URL}/api/inventory/removeuser`,
    id
  );
  return response.data;
};

const inventoryService = {
  searchUser,
  assignProduct,
  returnProduct,
  getInventoryUsers,
  shareInventory,
  removeUser,
};

export default inventoryService;
