import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Card from "../../card/Card";

import "./ProductForm.scss";

const ProductForm = ({
  product,
  productImage,
  imagePreview,
  description,
  setDescription,
  handleInputChange,
  handleImageChange,
  saveProduct,
}) => {
  return (
    <div className="add-product ">
      <center>
        <br />
        <Card>
          <form
            className=" form "
            autoComplete="off"
            onSubmit={(e) => saveProduct(e)}
          >
            <input
              autocomplete="false"
              name="hidden"
              type="text"
              style={{ display: "none" }}
            ></input>
            {/* <Card cardClass={"group"}>
              <label>Product Image</label>
              <code>Supported Formats: jpg, jpeg, png</code>
              <input
                type="file"
                name="image"
                onChange={(e) => handleImageChange(e)}
              />

              {imagePreview != null ? (
                <div className="image-preview">
                  <img src={imagePreview} alt="product" />
                </div>
              ) : (
                <p>No image set for this poduct.</p>
              )}
            </Card> */}

            <label className="nameClass">Product Name:</label>
            <input
              className=""
              type="text"
              placeholder="Product Name"
              name="name"
              value={product?.name}
              onChange={handleInputChange}
            />

            <label className="categoryClass">Product Category:</label>
            <input
              type="text"
              placeholder="Product Category"
              name="category"
              value={product?.category}
              onChange={handleInputChange}
            />

            <label className="priceClass">Product Price:</label>
            <input
              type="text"
              placeholder="Product Price"
              name="price"
              value={product?.price}
              onChange={handleInputChange}
            />

            <label className="quantityClass">Product Quantity:</label>
            <input
              type="text"
              placeholder="Product Quantity"
              name="quantity"
              value={product?.quantity}
              onChange={handleInputChange}
            />

            <label className="priceClass">Location:</label>
            <input
              type="text"
              placeholder="Location"
              name="location"
              value={product?.location}
              onChange={handleInputChange}
            />

            <label className="descriptionClass">Product Description:</label>
            <ReactQuill
              theme="snow"
              value={description}
              onChange={setDescription}
              modules={ProductForm.modules}
              formats={ProductForm.formats}
            />

            <div className="--my">
              <button type="submit" className="--btn --btn-primary save-btn">
                Save Product
              </button>
            </div>
          </form>
        </Card>
      </center>
    </div>
  );
};

ProductForm.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["clean"],
  ],
};
ProductForm.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "color",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "video",
  "image",
  "code-block",
  "align",
];

export default ProductForm;
