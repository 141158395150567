import { useState } from "react";
import axios from "axios";
import "./ShareInventory.css";
import Card from "../../components/card/Card";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useDispatch, useSelector } from "react-redux";
import { BACKEND_URL } from "../../services/authService";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import {
  getInventoryUsers,
  removeUser,
  selectInventoryUsers,
  selectIsLoading,
  shareInventory,
} from "../../redux/features/inventory/inventorySlice";
axios.defaults.withCredentials = true;

const ShareInventory = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const inventoryUsers = useSelector(selectInventoryUsers);

  const isLoading = useSelector(selectIsLoading);

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleShare = async (e) => {
    e.preventDefault();

    if (email.length <= 0) {
      toast.warning("Please enter valid email address");
    }

    await dispatch(shareInventory({ email }));
    dispatch(getInventoryUsers());
    setEmail("");
  };

  //remove user
  const confirmRemove = (id) => {
    confirmAlert({
      title: "Remove User",
      message: "Are you sure you want to remove this user from inventory.",
      buttons: [
        {
          label: "Remove",
          onClick: () => handleRemoveUser(id),
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const handleRemoveUser = async (id) => {
    try {
      await dispatch(removeUser({ id }));
      dispatch(getInventoryUsers());

      // return toast.success(response.data.message);
    } catch (err) {
      // return toast.error(err.response.data.message);
    }
  };

  useEffect(() => {
    dispatch(getInventoryUsers());
  }, []);

  return (
    <div className="main-container">
      <div>
        <div className="share">
          <form
            className=" --form-control add-user-form "
            autoComplete="off"
            onSubmit={(e) => handleShare(e)}
          >
            <input
              className="email"
              type="email"
              placeholder="Enter email"
              name="email"
              required
              value={email}
              onChange={handleEmail}
            />

            <button type="submit" className="--btn --btn-primary share-btn">
              Share
            </button>
          </form>
          <p>
            Note : All the users who have access your inventory can only see
            products and product details. They can't add, update and delete
            product or any kind of modification in your inventory.
          </p>
        </div>

        <div className="dashboard"></div>
      </div>
      <Card cardClass="users-container">
        <div>
          {isLoading && <Loader />}
          {inventoryUsers.map((user) => (
            <div className="profile-container --box-shadow">
              <div>
                <img src={user.photo}></img>
              </div>
              <div>
                <p>{user.name}</p>
              </div>
              <div className="buttons">
                <button className="--btn --btn-primary" type="button">
                  <NavLink to={`/profile/${user._id}`} className="view">
                    View
                  </NavLink>
                </button>
                <button
                  className="--btn --btn-danger"
                  type="button"
                  onClick={(e) => confirmRemove(user._id)}
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
};

export default ShareInventory;
