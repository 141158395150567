import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, NavLink } from "react-router-dom";

import useRedirectLoggedOutUser from "../../../customHook/useRedirectLoggedOutUser";
import { selectIsLoggedIn } from "../../../redux/features/auth/authSlice";
import { getProduct } from "../../../redux/features/product/productSlice";
import AssignProduct from "../../assignProduct/AssignProduct";
import Card from "../../card/Card";
import { SpinnerImg } from "../../loader/Loader";
import "./ProductDetail.css";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import { returnProduct } from "../../../redux/features/inventory/inventorySlice";

let moment = require("moment");
axios.defaults.withCredentials = true;

const ProductDetail = () => {
  useRedirectLoggedOutUser("/login");
  const dispatch = useDispatch();
  const [form, setForm] = useState(false);
  const [user, setUser] = useState("");
  const [returnQuantity, setReturnQuantity] = useState("");
  const [returnDate, setRetunDate] = useState("");
  const [historyID, setHistoryID] = useState("");
  const USER = JSON.parse(localStorage.getItem("user"));

  const { id } = useParams();

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { product, isLoading, isError, message } = useSelector(
    (state) => state.product
  );

  const stockStatus = (quantity) => {
    if (quantity > 0) {
      return <span className="--color-success">In Stock</span>;
    }
    return <span className="--color-danger">Out Of Stock</span>;
  };

  useEffect(() => {
    if (isLoggedIn === true) {
      dispatch(getProduct(id));
    }

    if (isError) {
      console.log(message);
    }
  }, [isLoggedIn, isError, message, dispatch]);

  //handleReturn
  const handleReturn = (id, data) => {
    setHistoryID(id);
    setForm(true);
    setUser(data);

    setReturnQuantity(data.consumeQuantity);
  };

  //handleChangeDate
  const handleChangeDate = (e) => {
    setRetunDate(e.target.value);
  };

  const handleConfirm = async (e, data) => {
    e.preventDefault();

    if (!returnQuantity || !returnDate) {
      return toast.error("All fields are required!");
    }

    if (+returnQuantity > user.consumeQuantity || +returnQuantity <= 0) {
      return toast.error("Please enter valid return quantity!");
    }

    if (
      moment(new Date(returnDate)).diff(moment(product.assignDate), "days") <=
      -2
    ) {
      return toast.error("Please enter valid return date!");
    }

    setForm(!form);

    await dispatch(
      returnProduct({
        userId: user.userId._id,
        historyID,
        productId: product._id,
        returnDate,
        returnQuantity,
        returns: true,
      })
    );

    await dispatch(getProduct(id));
  };

  //handlechange quantity
  const handleChageQuantity = (e) => {
    setReturnQuantity(e.target.value);
  };

  return (
    <div className="product-details-box">
      <div className="product-detail">
        <Card cardClass="card">
          {isLoading && <SpinnerImg />}
          {product && (
            <div className="detail">
              <h3 className="--mt">Product Detail</h3>

              {/* IMAGE RENDERING */}
              {/* <Card cardClass="group"> */}
              {/* {product?.image ? (
                  <img
                    src={product.image.filePath}
                    alt={product.image.fileName}
                  />
                ) : (
                  <p>No image set for this product</p>
                )} */}
              {/* </Card> */}
              <h4>Product Availability: {stockStatus(product.quantity)}</h4>
              <hr />
              <h4>
                <span className="badge">Name: </span> &nbsp; {product.name}
              </h4>

              <p>
                <b>&rarr; Category : </b> {product.category}
              </p>
              <p>
                <b>&rarr; Price : </b> {"₹"}
                {product.price}
              </p>
              <p>
                <b>&rarr; Quantity in stock : </b> {product.quantity}
              </p>

              <p>
                <b>&rarr; Location : </b>
                {product?.location}
              </p>
              {USER._id === product.user && <AssignProduct product={product} />}

              <hr />
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(product.description),
                }}
              ></div>
              <hr />
              <code className="--color-dark">
                Created on: {product.createdAt.toLocaleString("en-US")}
              </code>
              <br />
              <code className="--color-dark">
                Last Updated: {product.updatedAt.toLocaleString("en-US")}
              </code>
            </div>
          )}
        </Card>
        {product && (
          <div className="consume-assign-table">
            {console.log(product)}

            <div className="assign-table">
              <table className="fixed_header">
                <thead>
                  <tr>
                    <th>Assign To</th>
                    <th>Date</th>
                    <th>Quantity</th>
                    <th>Deadline(days)</th>
                    <th>Return Date</th>
                  </tr>
                </thead>
                <tbody>
                  {product.history
                    .filter((data) => data.consumeBy === "null")
                    .map((data) => {
                      return (
                        <tr
                          style={
                            data.remainItem === true
                              ? { borderLeft: "5px solid rgb(255,0,0)" }
                              : { borderLeft: "5px solid rgb(0,255,0)" }
                          }
                        >
                          <td>
                            <NavLink
                              to={`/profile/${data.userId._id}`}
                              className="view"
                              style={{ color: "black" }}
                            >
                              {" "}
                              {data.userId.name}
                            </NavLink>
                          </td>
                          <td>
                            {/* {moment("20010704T120854").format("LL")}
                             */}
                            {moment(new Date(data.assignDate)).format(
                              "MMM Do YY"
                            )}
                            {data.remainItem === true ? (
                              <GoArrowRight
                                style={{
                                  color: "red",
                                  marginLeft: "0.5rem",
                                }}
                              />
                            ) : (
                              <GoArrowLeft
                                style={{
                                  color: "green",
                                  marginLeft: "0.5rem",
                                }}
                              />
                            )}
                          </td>
                          <td>{data.consumeQuantity}</td>
                          <td>
                            {`${data.deadline}`}
                            {/* {moment(Date.now()).diff(
                              moment(data.assignDate),
                              "days"
                            ) -
                              Number(data.deadline) >=
                            1 ? (
                              <span
                                style={{ color: "red", paddingLeft: "0.5rem" }}
                              >
                                +
                                {moment(Date.now()).diff(
                                  moment(data.assignDate),
                                  "days"
                                ) - Number(data.deadline)}
                              </span>
                            ) : (
                              ""
                            )}

                            {moment(Date.now()).diff(
                              moment(data.assignDate),
                              "days"
                            ) -
                              Number(data.deadline) <
                              -1 && data.returnDate ? (
                              <span
                                style={{
                                  color: "green",
                                  paddingLeft: "0.5rem",
                                }}
                              >
                                {moment(Date.now()).diff(
                                  moment(data.assignDate),
                                  "days"
                                ) - Number(data.deadline)}
                              </span>
                            ) : (
                              ""
                            )} */}
                          </td>

                          {USER._id === product.user &&
                          product.remainItem === false ? (
                            <td>
                              <button
                                type="button"
                                className="--btn --btn-primary return-btn"
                                onClick={() => handleReturn(data._id, data)}
                              >
                                <GoArrowLeft
                                  style={{
                                    color: "white",
                                    paddingRight: "0.5rem",
                                  }}
                                />
                                Return
                              </button>
                            </td>
                          ) : (
                            <td>
                              {(USER._id === product.user &&
                                data.remainItem) === true ? (
                                <button
                                  type="button"
                                  className="--btn --btn-primary return-btn"
                                  onClick={() => handleReturn(data._id, data)}
                                >
                                  <GoArrowLeft
                                    style={{
                                      color: "white",
                                      paddingRight: "0.5rem",
                                    }}
                                  />
                                  Return
                                </button>
                              ) : (
                                moment(new Date(data.returnDate)).format(
                                  "MMM Do YY"
                                )
                              )}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="return-form-class">
              {form ? (
                <div className="return-form">
                  <div className="assign-form">
                    {user.consumeBy !== "null" && (
                      <p>
                        Consumed By: <span>{user.consumeBy}</span>
                      </p>
                    )}
                    <p>
                      Return from: <span>{user.userId.name}</span>
                    </p>
                    <p>
                      Assign Date:{" "}
                      <span>
                        {" "}
                        {moment(new Date(user.assignDate)).format("MMM Do YY")}
                      </span>
                    </p>
                    <p>
                      Assign Quantity: <span>{user.consumeQuantity}</span>
                    </p>
                    <form>
                      <div>
                        <label for="quantity">Enter Return Quantity:</label>

                        <input
                          type="number"
                          id="quantity"
                          placeholder="Quantity "
                          required
                          onChange={handleChageQuantity}
                          min="1"
                          max={user.consumeQuantity}
                          value={returnQuantity}
                        ></input>
                      </div>
                      <div>
                        <label for="date">Return Date:</label>

                        <input
                          type="date"
                          id="date"
                          required
                          onChange={handleChangeDate}
                        ></input>
                      </div>

                      <div className="cancel-confirm">
                        <button
                          type="button"
                          className="--btn cancel"
                          onClick={() => setForm(!form)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className=" --btn --btn-primary confirm"
                          onClick={(e) => handleConfirm(e, product)}
                        >
                          Confirm
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              ) : null}
            </div>
            {/* CONSUMED TABLE */}
            <div className="consume-table">
              <table className="fixed_header">
                <thead>
                  <tr>
                    <th>Consumed By</th>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Quantity</th>
                    <th>Return Date</th>
                  </tr>
                </thead>

                <tbody>
                  {product.history
                    .filter((data) => data.consumeBy !== "null")
                    .map((data) => {
                      return (
                        <tr
                          style={
                            data.remainItem === true
                              ? { borderLeft: "5px solid rgb(255,0,0)" }
                              : { borderLeft: "5px solid rgb(0,255,0)" }
                          }
                        >
                          <td>{data.consumeBy}</td>
                          <td>{data.userId.name}</td>
                          <td>
                            {moment(new Date(data.assignDate)).format(
                              "MMM Do YY"
                            )}
                            {data.remainItem === true ? (
                              <GoArrowRight
                                style={{
                                  color: "red",
                                  marginLeft: "0.5rem",
                                }}
                              />
                            ) : (
                              <GoArrowLeft
                                style={{
                                  color: "green",
                                  marginLeft: "0.5rem",
                                }}
                              />
                            )}
                          </td>
                          <td>{data.consumeQuantity}</td>

                          {USER._id === product.user ? (
                            <td>
                              <button
                                type="button"
                                className="--btn --btn-primary return-btn"
                                onClick={() => handleReturn(data._id, data)}
                              >
                                <GoArrowLeft
                                  style={{
                                    color: "white",
                                    paddingRight: "0.5rem",
                                  }}
                                />
                                Return
                              </button>
                            </td>
                          ) : (
                            <td>
                              {data.remainItem === true
                                ? "not return"
                                : moment(new Date(data.returnDate)).format(
                                    "MMM Do YY"
                                  )}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDetail;
